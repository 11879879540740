<template>
    <div>
        <div v-if="position === sportsConst.ENABLE">
            <button class="badge badge-red" style="padding: 5px 15px;margin-left: 2px" @click="deleteAllBetInfo">
                <i class="fa fa-close"> Delete All</i>
            </button>
        </div>

        <div class="mybet" v-for="(betinfo,index) in betList" :key="index">
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_NOT">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList" :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>

                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_CANCELED
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_SPECIAL
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_WATING">
                                    {{betInfoGame.game.homeTeamScore}}:{{betInfoGame.game.awayTeamScore}}

                            </span>
                            <span class="score text-red"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_CANCELED">
                                     Canceled
                                    </span>
                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_SPECIAL">
                                      Hit special
                                    </span>
                            <span class="score text-white"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_WATING">
                                     Waiting
                                    </span>
                            <span class="st"
                                  style="float: right">{{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}</span>
                        </div>
                        <div class="match clearfix">
                            <div class="start_time">
                                {{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}
                            </div>
                            <div class="team_info">
                                <div class="detail">
                                    <div class="home bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_HOME}">
                                        <span class="home_name" v-html="betInfoGame.game.homeTeamName"></span>
                                        <span class="home_odds">
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square text-green"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-up text-red"></i>
                                            {{betInfoGame.homeTeamOdds}}
                                        </span>
                                    </div>
                                    <!--무 3way-->
                                    <div class="draw bg_1x2"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds > 0"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_DRAW}">
                                        <span class="draw_bedang">{{betInfoGame.drawOdds}}</span>
                                    </div>
                                    <div class="draw ho"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds <= 0">
                                        <span>vs</span>
                                    </div>
                                    <!--핸디캡 오버언더-->
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                         class="draw ho">
                                        {{betInfoGame.handicapVal}}
                                    </div>
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                         class="draw ho">
                                        {{betInfoGame.overunderVal}}
                                    </div>

                                    <div class="away bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_AWAY}">
                                        <span class="away_name" v-html="betInfoGame.game.awayTeamName">
                                                                       </span>
                                        <span class="away_odds">
                                            {{betInfoGame.awayTeamOdds}}
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square text-green"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-down text-blue"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more">
                                 <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                       Win
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                    Lose
                                </span>
                                <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                     Hit special
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 4">
                                     Canceled
                                  </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_YES">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList"
                     :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>
                            <span class="inplay_team_name">{{betInfoGame.game.homeTeamName}} vs {{betInfoGame.game.awayTeamName}}</span>
                        </div>
                        <ul>
                            <li>
                                <table class="table">
                                    <tr>
                                        <th>Section</th>
                                        <th>Kind</th>
                                        <th>Attr</th>
                                        <th>Odds</th>
                                        <th>Result</th>
                                    </tr>
                                    <tr>
                                        <td>{{betInfoGame.ito1}}</td>
                                        <td>{{betInfoGame.ito2}}</td>
                                        <td class="inplay_selected">{{betInfoGame.ito3}}</td>
                                        <td style="text-align: center">{{betInfoGame.selectedOdds}} <span v-if="betInfoGame.score !== undefined"
                                                                               class="score">{{' ('+betInfoGame.score+')'}}</span>
                                        </td>
                                        <td>
                                        <span class="text-white" v-if="betInfoGame.betGameResult === 0">
                                                Waiting
                                              </span>
                                            <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                                Win
                                         </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            Lose
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                             Hit special
                                          </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 4">
                                             Canceled
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="betInfo">
                <span class="chk">

<!--                  <input v-if="position === sportsConst.ENABLE" type="checkbox"-->
<!--                         @click="checkBetId(betinfo.id)" style="margin-right: 5px">-->

                     <a href="javascript:void(0)" class="badge badge-red"
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING
                       && betinfo.isinplay === sportsConst.INPLAY_NOT && position === sportsConst.ENABLE"
                        @click="cancelBet(betinfo.id)">Cancel</a>
                    <a href="javascript:void(0)" class="badge badge-red"
                       v-if="betinfo.betResult !== sportsConst.BET_RESULT_WAITING && position === sportsConst.ENABLE"
                       @click="deleteBet(betinfo.id)">  <i class="fa fa-close"> Delete</i></a>

                    <router-link href="javascript:void(0)" tag="a"  v-if="position === sportsConst.ENABLE"
                                 :to="{path: '/customer_center', query: {sbid:betinfo.id,t: new Date().getTime()}}"
                                 class="badge badge-success ml-2">  <i class="fa fa-question-circle-o"> Contact Us</i></router-link>

                </span>
                <span class="time" style="color: #fff9ff">
                  <i class="fa fa-clock-o"></i>&nbsp;
                  <i>{{betinfo.createTime|datef('MM/DD HH:mm:ss')}}</i>
                </span>
                <span
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING" class="text-white">Waiting</span>
                <span class="text-skyblue"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_WIN"><i class="fa fa-trophy"></i> Win</span>
                <span class="text-red"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_LOSE">Lose</span>
                <span class="text-orange"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_SPECIALCASE">[Hit Special]</span>
                <span class="text-red"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_CANCEL">[Canceled]
                            </span>


                <span class="odds text-skyblue">Odds: {{betinfo.betOddsTotal}}</span>
                <span class="betCash text-skyblue">Amount: {{betinfo.betCash|comma}}</span>
                <span class="wincash text-skyblue">
                Winning:
                {{(betinfo.totalCash)|comma}}&nbsp;<i v-if="betinfo.bonusCash > 0"
                                                      style="font-style: normal">(+{{betinfo.bonusCash|comma}})</i>
                </span>


                <!--                <span v-if="position">-->
                <!--                    <a href="javascript:void(0)" class="badge badge-success"-->
                <!--                       v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING-->
                <!--                       && betinfo.isinplay === sportsConst.INPLAY_NOT"-->
                <!--                       @click="cancelBet(betinfo.id)">취소</a>-->
                <!--                    <a href="javascript:void(0)" class="badge badge-success"-->
                <!--                       v-if="betinfo.betResult !== sportsConst.BET_RESULT_WAITING"-->
                <!--                       @click="deleteBet(betinfo.id)">삭제</a>-->
                <!--                </span>-->

            </div>
        </div>
    </div>

</template>

<script>
    import {cancelBet, deleteAllBet, deleteBet} from "../../network/sportsBetRequest";
    import sportsConst from "../../common/sportsConst";
    import BoardWriter from "../BoardWriter";

    export default {
        name: "SportsBetListComp",
        components: {BoardWriter},
        data() {
            return {
                sportsConst,
                betIds: [],
            }
        },
        props: {
            betList: {
                type: Array,
                default: []
            },
            position: {
                type: Number,
                default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
            }
        },
        methods: {
            deleteAllBetInfo() {
                this.$swal({
                    title: 'Do you want to delete your entire betting history?',
                    //title: '전체 베팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' Yes ',
                    cancelButtonText: ' No'
                }).then((res) => {
                    if (res.value) {
                        deleteAllBet().then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            checkBetId(id) {
                let index = this.betIds.findIndex((item) => {
                    return item === id
                });
                if (index === -1) {
                    this.betIds.push(id)
                } else {
                    this.betIds.splice(index, 1)
                }
                this.$emit('betInfoChecked', this.betIds)
            },
            cancelBet(id) {
                this.$swal({
                    //title: '베팅내역을 취소하시겠습니까?',
                    title: 'Would you like to cancel your bet?',
                    type: 'question',
                    confirmButtonText: ' Yes ',
                    cancelButtonText: ' No '
                }).then((res) => {
                    if (res.value) {
                        cancelBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })


            },
            deleteBet(id) {

                this.$swal({
                   // title: '베팅내역을 삭제하시겠습니까?',
                    title: 'Do you want delete the Betting details?',
                    type: 'question',
                    confirmButtonText: ' Yes ',
                    cancelButtonText: ' No '
                }).then((res) => {
                    if (res.value) {
                        deleteBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .table th,td{
      text-align: center;
    }

    .league .score {
        float: right;
        margin: 0 3px;
    }

    .league .st {
        display: none;
    }

    .betInfo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: var(--noticeBg);
        line-height: 36px;
        margin-bottom: 20px;
        border-bottom: 4px solid #ffffff;
    }

    .betInfo span {
        display: block;
        width: 33.3%;
        color: #d9d9d9;
        box-sizing: border-box;
        padding-left: 3px;
    }

    .match_area table {
        font-size: 12px;
    }


    .match_area table th, td {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #1a4050;
    }

    .btn-del-all {
        width: 120px;
        height: 30px;
        background-color: #ff0000;
        margin: 5px 0;
        border-radius: 3px;
    }

    .match_area .inplay_team_name {
        margin-left: 20px;
        color: #f4c600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
        .league .st {
            display: inline-block;
            color: #c4c4c3;
            margin-right: 20px;
        }

        .match_area .inplay_team_name {
            margin-left: 5px;
        }
    }


</style>